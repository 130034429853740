import React, {useContext, Fragment} from 'react';
import styled from 'styled-components';
import {Context, Actions} from '../Contexts/AppContext';
import {Cross} from './SvgIcon';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  visibility: ${props => (props.isShow ? 'visible' : 'collapse')};
  z-index: 10000;
  & > .back_drop {
    position: absolute;
    top: 0;
    right: ${props => (props.isShow ? '0' : '100%')};
    width: 100%;
    height: 100%;
    background: #000;
    opacity: ${props => (props.isShow ? 0.3 : 0)};
    transition: opacity 500ms ease-in-out;
  }
  & > .slider {
    position: absolute;
    top: 0;
    right: ${props => (props.isShow ? '0' : '-100%')};
    width: 320px;
    height: 100%;
    background: #fff;
    transition: right 500ms ease-in-out;
    & > .content {
      position: relative;
    }
    & svg {
      :hover {
        cursor: pointer;
      }
    }
  }
`;

const SlidePanel = props => {
  const {children = null, setIsSlidePanelShow} = props;
  const isShow = children !== null;
  const dismissSlidePanel = () => {
    setIsSlidePanelShow(false);
  };
  return (
    <Wrapper isShow={isShow}>
      <div className="back_drop" onClick={dismissSlidePanel} />
      <div className="slider">
        <Cross
          onClick={dismissSlidePanel}
          style={{position: 'absolute', top: 20, right: 20}}
        />
        <div className="content">{children}</div>
      </div>
    </Wrapper>
  );
};

export default SlidePanel;
