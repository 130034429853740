import React, {Fragment, useContext} from 'react';
import styled from 'styled-components';
import {Context} from '../Contexts/AppContext';
import * as Widget from '../Components/Widget';
import RefineBar from '../Components/RefineBar';
import ProductGrid from '../Components/ProductGrid';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../Components/RectButton';
import * as L from '../Utils/Lang';
import queryString from 'query-string';
import useFetchProducts from '../Hooks/useFetchProducts';
import useProductGridProps from '../Hooks/useProductGridProps';
import {useDimension} from '../Hooks/AppHooks';
import CustomNav from '../Components/CustomNav';
import {MiniCartIcon} from '../Components/MiniCartIcon';
import {Color, FlexCol} from '../Components/Widget';

const Collection = props => {
  const {queryParams} = props;
  const {tablet, mobile} = useDimension();
  const app = useContext(Context);
  const {collection_id: collectionId} = queryParams;
  const {itemPerRow, itemWidth} = useProductGridProps();
  const {
    updateFilter,
    updateSort,
    loadNextPage,
    selectedFilterCnt,
    totalCount,
    sort,
    filterOptions,
    products,
  } = useFetchProducts({
    brand: null,
    collectionIds: collectionId,
    isFetch: true,
  });

  const targetCollection = app.brandCats[app.activeBrand]
    .reduce((acc, cur) => acc.concat(cur.collections), [])
    .find(collection => collection.id === parseInt(collectionId));

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      {(tablet || mobile) && <CustomNav right={<MiniCartIcon />} />}

      <Wrapper mobile={mobile} data-nav-type="solid">
        <div className="category">{L.d(targetCollection?.title)}</div>

        <RefineBar
          mobile={mobile}
          filter={filterOptions}
          sort={sort}
          sortOptions={['+price', '-price', '-created']}
          onUpdateFilter={updateFilter}
          onUpdateSort={updateSort}
          selectedFilterCnt={selectedFilterCnt}
        />

        <div style={{height: 40}} />

        {products.length !== 0 && (
          <ProductGrid
            products={products}
            itemPerRow={itemPerRow}
            itemWidth={itemWidth}
          />
        )}
        {products.length === 0 && (
          <FlexCol style={{alignItems: 'center', color: Color.mainDark_70}}>
            <div>未找到任何商品</div>
            <div>請嘗試刪除部分篩選條件</div>
          </FlexCol>
        )}

        {products.length < totalCount && (
          <Widget.FlexCenter style={{padding: 20}}>
            <RectButton
              skin={BUTTON_SKIN.DEFAULT}
              size={mobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
              style={{margin: '0 auto', width: '160px'}}
              onClick={loadNextPage}
              text="更多商品"
            />
          </Widget.FlexCenter>
        )}
      </Wrapper>
    </div>
  );
};

const ProductListPage = props => {
  const {location} = props;
  const queryParams = queryString.parse(location.search);
  const collection_id = queryParams.collection_id;
  return <Collection key={collection_id} queryParams={queryParams} />;
};

const Wrapper = styled.div`
  padding: 20px ${props => (props.mobile ? 20 : 40)}px;
  flex: 1;
  max-width: 1440px;
  min-height: 100vh;

  & > .category {
    font-size: 30px;
    line-height: 38px;
  }
`;

export default ProductListPage;
