import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {KeyboardArrowDown} from '@styled-icons/material';
import * as Widget from '../Components/Widget';
import {Color} from '../Components/Widget';
import FilterPanel from '../Components/FilterPanel';
import SortPanel from '../Components/SortPanel';
import {SortLabels} from './SortPanel';
import {FlexRowCenter} from './Widget';
import {useDimension} from '../Hooks/AppHooks';
import SlidePanel from './SlidePanel.js';

function RefineBar(props) {
  const {
    filter,
    selectedFilterCnt,
    sort,
    sortOptions,
    onUpdateFilter,
    onUpdateSort,
    hint,
    style,
    brandMenuList,
  } = props;
  const filterPanelRef = React.useRef();
  const sortPanelRef = React.useRef();
  const {mobile, desktop} = useDimension();
  const [isSlidePanelShow, setIsSlidePanelShow] = useState(false);

  return (
    <>
      <RefineBarWrapper style={style} mobile={mobile}>
        {hint && <div className="hint">{hint}</div>}
        <FlexRowCenter style={mobile ? {flex: 1} : {}}>
          {brandMenuList && !desktop && (
            <Fragment>
              <Widget.FlexRow
                className="item"
                onClick={() => {
                  setIsSlidePanelShow(true);
                }}>
                <div style={{fontSize: mobile ? '12px' : '16px'}}>
                  品牌/類別
                </div>
                <KeyboardArrowDown
                  size={20}
                  color="#666"
                  style={{marginLeft: 8}}
                />
              </Widget.FlexRow>
              <div style={{width: mobile ? '10px' : '40px'}} />
            </Fragment>
          )}
          <Widget.FlexRow
            className="item"
            onClick={() => {
              filterPanelRef.current.open(filter);
            }}>
            <div style={{fontSize: mobile ? '12px' : '16px'}}>篩選</div>
            {selectedFilterCnt > 0 && (
              <div
                style={{
                  marginLeft: 8,
                  color: '#141414',
                  fontSize: mobile ? '12px' : '16px',
                }}>
                ({selectedFilterCnt})
              </div>
            )}
            <KeyboardArrowDown
              size={20}
              color="black"
              style={{marginLeft: 8}}
            />
          </Widget.FlexRow>
          <div style={{width: mobile ? '10px' : '40px'}} />
          <Widget.FlexRow
            className="item"
            onClick={() => {
              sortPanelRef.current.open({sortOptions, selectedOption: sort});
            }}>
            <div
              style={{
                fontSize: mobile ? '12px' : '16px',
                whiteSpace: 'nowrap',
              }}>
              排序
            </div>
            {!mobile && sort && (
              <div
                style={{
                  marginLeft: 8,
                  color: '#666',
                  fontSize: mobile ? '12px' : '16px',
                  whiteSpace: 'nowrap',
                }}>
                {SortLabels[sort] || sort}
              </div>
            )}
            <KeyboardArrowDown size={20} color="#666" style={{marginLeft: 8}} />
          </Widget.FlexRow>
        </FlexRowCenter>
      </RefineBarWrapper>

      <FilterPanel
        getInstance={inst => {
          filterPanelRef.current = inst;
        }}
        onConfirm={onUpdateFilter}
        mobile={mobile}
      />

      <SortPanel
        getInstance={inst => {
          sortPanelRef.current = inst;
        }}
        onConfirm={onUpdateSort}
        mobile={mobile}
      />

      <SlidePanel setIsSlidePanelShow={setIsSlidePanelShow}>
        {isSlidePanelShow ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '20px 20px',
              marginTop: '40px',
            }}>
            {brandMenuList}
          </div>
        ) : null}
      </SlidePanel>
    </>
  );
}

const RefineBarWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: ${props => (props.mobile ? 'space-between' : 'flex-end')};
  border-bottom: ${props => (props.mobile ? 'none' : '1px solid #ccc')};
  & > .hint {
    color: ${Color.mainDark_70};
  }

  & .item {
    padding: 12px 0px;
    cursor: pointer;
    ${props =>
      props.mobile
        ? `
    flex: 1;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    `
        : ''}

    & > div::first-child {
      margin-right: 10px;
    }
  }
`;

export default RefineBar;
