import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Actions, Context} from '../Contexts/AppContext';
import {convert, deconvert} from '../Utils/FilterUtil';

const useFetchProducts = ({
  brand,
  categoryIds,
  collectionIds,
  subCollectionIds,
  keyword,
  isFetch = false,
}) => {
  const app = useContext(Context);
  const DEFAULT_LIMIT = 48;
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState([]);
  const [fetchParam, setFetchParam] = useState({
    brand,
    categoryIds: categoryIds,
    collectionId: collectionIds,
    subCollectionIds: subCollectionIds,
    filter: [],
    sort: '-created',
    offset: 0,
    limit: DEFAULT_LIMIT,
    keyword: keyword,
  });

  const fetchProducts = useCallback(async curFetchParams => {
    try {
      Actions.setLoading(true);

      const data = await Actions.fetchProducts({
        ...curFetchParams,
        filter: deconvert(curFetchParams.filter),
      });

      setProducts(data.products);
      setFetchParam({...curFetchParams, offset: DEFAULT_LIMIT});
      setTotalCount(data.total_count);
      window.scrollTo(0, 0);
    } catch (e) {
      console.error(e);
    }
    Actions.setLoading(false);
  }, []);

  useEffect(() => {
    if (isFetch) {
      fetchProducts({
        brand,
        categoryIds: categoryIds,
        collectionId: collectionIds,
        subCollectionIds: subCollectionIds,
        filter: [],
        sort: '-created',
        offset: 0,
        limit: DEFAULT_LIMIT,
        keyword: keyword,
      });
    }
  }, [
    brand,
    categoryIds,
    collectionIds,
    subCollectionIds,
    fetchProducts,
    isFetch,
    app.currentUser,
    keyword,
  ]);

  const getInitFilterOptions = useCallback(async () => {
    const resp = await Actions.fetchProducts({
      brand,
      categoryIds: categoryIds,
      collectionId: collectionIds,
      subCollectionIds: subCollectionIds,
      filter: [],
      sort: '-created',
      offset: 0,
      limit: DEFAULT_LIMIT,
      keyword: keyword,
    });
    setFilterOptions(convert(resp.filters));
  }, [brand, categoryIds, collectionIds, subCollectionIds, keyword]);

  useEffect(() => {
    if (isFetch) {
      getInitFilterOptions();
    }
  }, [getInitFilterOptions, isFetch]);

  const loadNextPage = useCallback(async () => {
    try {
      Actions.setLoading(true);
      const data = await Actions.fetchProducts({
        ...fetchParam,
        filter: deconvert(fetchParam.filter),
        limit: 12,
      });
      setProducts(prev => [...prev, ...data.products]);
      window.scrollTo(0, window.scrollY);
      setTotalCount(data.total_count);
      setFetchParam(prev => ({
        ...prev,
        offset: fetchParam.offset + 12,
      }));
    } catch (e) {
      console.error(e);
    }
    Actions.setLoading(false);
  }, [fetchParam]);

  const selectedFilterCnt = useMemo(() => {
    let total = 0;
    for (const cfg of filterOptions) {
      total += (cfg.options || []).filter(opt => opt.selected).length;
    }
    return total;
  }, [filterOptions]);

  const updateFilter = async nextFilter => {
    setFilterOptions(nextFilter);
    await fetchProducts({
      ...fetchParam,
      offset: 0,
      filter: nextFilter,
    });
  };

  const updateSort = async nextSort => {
    await fetchProducts({
      ...fetchParam,
      offset: 0,
      sort: nextSort,
    });
  };

  return {
    updateFilter,
    updateSort,
    selectedFilterCnt,
    loadNextPage,
    totalCount,
    sort: fetchParam.sort,
    filterOptions,
    products,
    fetchProducts,
    getInitFilterOptions,
  };
};

export default useFetchProducts;
