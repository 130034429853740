import React from 'react';
import SlideInPanel from './SlideInPanel';
import styled from 'styled-components';
import * as Widget from './Widget';
import {Close} from '@styled-icons/evil';
import {Plus, Minus} from './SvgIcon';
import _ from 'lodash';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';
import {Color, FlexRow} from './Widget';

function FilterPanelContent(props) {
  const {onClose, onConfirm, configs} = props;
  const [currConfigs, setCurrConfigs] = React.useState(configs);
  const toggleOption = React.useCallback(
    (configIdx, optionIdx) => {
      const nextConfigs = _.cloneDeep(currConfigs);
      const nextSelected = !nextConfigs[configIdx].options[optionIdx].selected;
      nextConfigs[configIdx].options[optionIdx].selected = nextSelected;
      setCurrConfigs(nextConfigs);
      onConfirm(nextConfigs);
    },
    [currConfigs],
  );

  function countSelectedFilter() {
    let total = 0;
    for (const cfg of currConfigs) {
      total += cfg.options.filter(opt => opt.selected).length;
    }
    return total;
  }

  function clearSelectedFilter() {
    const nextConfigs = _.cloneDeep(currConfigs);
    for (const cfg of nextConfigs) {
      for (const opt of cfg.options) {
        delete opt.selected;
      }
    }
    setCurrConfigs(nextConfigs);
    onConfirm(nextConfigs);
  }

  const selectedCnt = countSelectedFilter();

  return (
    <Wrapper>
      <Widget.FlexRow style={{paddingBottom: 10}}>
        <HeaderText style={{flex: 1}}>
          {(selectedCnt > 0 && `篩選 (${selectedCnt})`) || `篩選`}
        </HeaderText>
        <Widget.ClearButton
          onClick={onClose}
          style={{backgroundColor: 'white'}}>
          <Close size={24} color="#666" />
        </Widget.ClearButton>
      </Widget.FlexRow>

      <div
        style={{
          height: 'calc(100% - 140px)',
          overflow: 'auto',
        }}>
        {currConfigs.map((config, idx) => (
          <div
            key={idx}
            style={{
              borderBottom: '1px solid #ccc',
              paddingBottom: 24,
              marginBottom: 24,
            }}>
            <Config
              config={config}
              toggleOption={toggleOption.bind(null, idx)}
            />
          </div>
        ))}
      </div>
      <Widget.FlexColCenter style={{paddingTop: 10, alignItems: 'center'}}>
        <RectButton
          skin={BUTTON_SKIN.PRIMARY}
          text="確定"
          size={BUTTON_SIZE.LARGE}
          style={{width: '100%'}}
          onClick={() => {
            onClose();
          }}
        />
        <Widget.UnderlineButton
          style={{margin: '16px 0', backgroundColor: 'transparent'}}
          onClick={() => {
            clearSelectedFilter();
          }}>
          清除篩選條件
        </Widget.UnderlineButton>
      </Widget.FlexColCenter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
`;

function Config(props) {
  const {config, toggleOption} = props;
  const [expand, setExpand] = React.useState(false);
  const options = expand ? config.options : config.options.slice(0, 3);

  return (
    <ConfigWrapper>
      <div style={{fontSize: 16, fontWeight: '500', marginBottom: 10}}>
        {config.name}
      </div>

      {options.map((option, idx) => {
        return (
          <Widget.CheckButton
            key={idx}
            checked={option.selected}
            style={{marginBottom: 10}}
            onClick={() => toggleOption(idx)}>
            {option.value}
          </Widget.CheckButton>
        );
      })}

      {config.options.length > 3 && (
        <Widget.ClearButton
          onClick={() => setExpand(!expand)}
          style={{
            backgroundColor: 'transparent',
            padding: 0,
            textAlign: 'left',
          }}>
          <Widget.FlexRow>
            <div style={{color: Color.mainDark_70}}>
              {expand ? (
                <FlexRow>
                  <div style={{marginRight: 8}}>收合</div>
                  <Minus color={Color.mainDark_70} />
                </FlexRow>
              ) : (
                <FlexRow>
                  <div style={{marginRight: 8}}>更多</div>
                  <Plus color={Color.mainDark_70} />
                </FlexRow>
              )}
            </div>
          </Widget.FlexRow>
        </Widget.ClearButton>
      )}
    </ConfigWrapper>
  );
}

const ConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

function FilterPanel(props) {
  const {getInstance, onConfirm} = props;
  const panelRef = React.useRef(null);

  if (getInstance) {
    getInstance({
      open: configs => {
        panelRef.current.open(
          <FilterPanelContent
            key={Math.random()}
            configs={configs}
            onConfirm={onConfirm}
            onClose={() => {
              panelRef.current.close();
            }}
          />,
        );
      },
      close: () => {
        panelRef.current.close();
      },
    });
  }

  return (
    <SlideInPanel
      zIndex={10000}
      size={320}
      style={{backgroundColor: 'white', padding: '16px 20px'}}
      getInstance={inst => {
        panelRef.current = inst;
      }}
    />
  );
}

export default FilterPanel;
