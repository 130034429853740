import * as L from '../Utils/Lang';

const FILTER_NAME_MAP = {
  filter_color: '顏色',
  filter_size: '尺寸',
  filter_gem: '寶石材質',
  filter_cat: '類別',
  filter_series: '系列',
  filter_metal: '金屬材質',
  filter_pattern: '款式',
  filter_br: '品牌',
  filter_theme: '主題',
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

const convert = filters => {
  if (!Array.isArray(filters)) {
    return [];
  }
  return filters.map(filter => {
    const key = Object.keys(filter)[0];
    try {
      return {
        name: FILTER_NAME_MAP[key],
        options: L.d(filter[key]).map(option => ({value: option})),
      };
    } catch (e) {
      return [];
    }
  });
};

const deconvert = filters => {
  return filters.map(filter => {
    const key = getKeyByValue(FILTER_NAME_MAP, filter.name);
    return {
      [key]: filter.options
        .filter(options => options.selected)
        .map(option => option.value),
    };
  });
};

export {convert, deconvert};
