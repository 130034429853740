import React from 'react';
import SlideInPanel from './SlideInPanel';
import styled from 'styled-components';
import * as Widget from './Widget';
import {Close} from '@styled-icons/evil';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';

export const SortLabels = {
  '+price': '$ 由低到高',
  '-price': '$ 由高到低',
  '-created': '最新上架',
};

function SortPanelContent(props) {
  const {options, selectedOption, onClose, onConfirm} = props;
  const [currOption, setCurrOption] = React.useState(selectedOption);
  return (
    <Wrapper>
      <Widget.FlexRow style={{paddingBottom: 10}}>
        <HeaderText style={{flex: 1}}>排序</HeaderText>
        <Widget.ClearButton
          onClick={onClose}
          style={{backgroundColor: 'white'}}>
          <Close size={24} color="#666" />
        </Widget.ClearButton>
      </Widget.FlexRow>
      <div
        style={{
          height: 'calc(100% - 110px)',
          overflow: 'auto',
        }}>
        {options.map((option, idx) => {
          const selected = currOption === option;
          return (
            <Widget.CheckButton
              key={idx}
              style={{marginBottom: 20}}
              checked={selected}
              onClick={() => {
                setCurrOption(option);
                onConfirm(option);
              }}>
              {SortLabels[option] || option}
            </Widget.CheckButton>
          );
        })}
      </div>
      <Widget.FlexColCenter style={{paddingTop: 10}}>
        <RectButton
          skin={BUTTON_SKIN.PRIMARY}
          text="確定"
          size={BUTTON_SIZE.LARGE}
          style={{width: '100%'}}
          onClick={() => {
            onClose();
          }}
        />
      </Widget.FlexColCenter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

function SortPanel(props) {
  const {getInstance, mobile, onConfirm} = props;
  const panelRef = React.useRef(null);

  if (getInstance) {
    getInstance({
      open: ({sortOptions, selectedOption}) => {
        panelRef.current.open(
          <SortPanelContent
            key={Math.random()}
            selectedOption={selectedOption}
            options={sortOptions}
            onConfirm={onConfirm}
            onClose={() => {
              panelRef.current.close();
            }}
          />,
        );
      },
      close: () => {
        panelRef.current.close();
      },
    });
  }

  return (
    <SlideInPanel
      zIndex={10000}
      size={320}
      style={{backgroundColor: 'white', padding: '16px 20px'}}
      getInstance={inst => {
        panelRef.current = inst;
      }}
    />
  );
}

export default SortPanel;
