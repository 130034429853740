import React, {useContext} from 'react';
import {useDimension} from './AppHooks';
import {Context} from '../Contexts/AppContext';

const useProductGridProps = () => {
  const {dimension, mobile} = useDimension();
  const config = {
    desktop: {gutter: 20, margin: 40},
    mobile: {gutter: 20, margin: 20},
  }[mobile ? 'mobile' : 'desktop'];
  const itemPerRow = mobile ? 2 : 4;
  const extraSpace = 2 * config.margin + (itemPerRow - 1) * config.gutter;
  const itemWidth =
    dimension.innerWidth >= 1440
      ? (1440 - extraSpace) / itemPerRow
      : (dimension.innerWidth - extraSpace) / itemPerRow;

  return {
    itemPerRow,
    itemWidth,
  };
};

export default useProductGridProps;
